import React from 'react';
import { connect } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";

// modules
import { onChangeLanguage } from "../../../../store/modules/Home";

// language pack
import { SlideLanguage } from "../../../../languagePack";

// CSS
import './MSwiper.css';

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, Autoplay  } from "swiper";

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Autoplay ]);

let Slide = props =>{
    return (
        <div id="MSwiper">
            <Swiper 
                slidesPerView={1}
                freeMode= {false}
                autoplay={{ delay : 3000 }}
                navigation={true}>
          
                <SwiperSlide>
                    <div className="row_3">
                        <img src="/img/asset/wallet.png" alt=""/>
                    </div>

                    <div className="row_1">
                        <div className="title">Blockchain Wallet</div>

                        <div className="explanation">
                            {SlideLanguage[props.lan][0]}
                        </div> 

                        <div className="count">1</div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="row_3">
                        <img src="/img/asset/jobus.png" alt=""/>
                    </div>

                    <div className="row_1">
                        <div className="title jobus">
                            <img src="/img/asset/jobus_logo.svg" alt="" />
                        </div>

                        <div className="explanation">
                            {SlideLanguage[props.lan][1]}
                        </div> 

                        <div className="count">2</div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="row_3">
                        <img src="/img/asset/block.png" alt=""/>
                    </div>

                    <div className="row_1">
                        <div className="title explorer">Block Explorer</div>

                        <div className="explanation">
                            {SlideLanguage[props.lan][2]}
                        </div> 

                        <div className="count">3</div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="row_3">
                        <img src="/img/asset/iconex.png" alt=""/>
                    </div>

                    <div className="row_1">
                        <div className="title iconex">ICON<span>EX</span></div>

                        <div className="explanation">
                            {SlideLanguage[props.lan][3]}
                        </div> 

                        <div className="count">4</div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="row_3">
                        <img src="/img/asset/flexmall.png" alt=""/>
                    </div>

                    <div className="row_1">
                        <div className="title flexmall">FLE<span>X</span>MALL</div>
                        
                        <div className="explanation">
                            {SlideLanguage[props.lan][4]}
                        </div>
    
                        <div className="count">5</div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
      )
}

const mapStateToProps = ({ Home }) => {
    return {
      lan: Home.lan
    };
};
  
Slide = connect(
mapStateToProps,
{ onChangeLanguage }
)(Slide);

export default Slide;
