import React from "react";
import { connect } from "react-redux";
import {Link} from 'react-scroll'

// modules
import { onChangeLanguage } from "../../../../store/modules/Home";

// CSS
import "./Header.css";

let Header = (props) => {
  return (
    <div id="Header">
      <div className="web_header_contents">
        <div id="right"></div>

        <div className="logo row">
          <Link to="Main" smooth={true} spy={true}>
            <img src="/img/header/white_logo.svg" alt=""/>
          </Link>
        </div>

        <div className="row">
          <Link to="Main" smooth={true} spy={true}>HOME</Link>
        </div>

        <div className="row">
          <Link to="Partner" smooth={true} spy={true}>COMPANY</Link>
        </div>

        <div className="row">
          <Link to="Project" smooth={true} spy={true}>PROJECT</Link>
        </div>

        <div className="row">
          <Link to="Media" smooth={true} spy={true}>MEDIA</Link>
        </div>

        <div className="row language">
          <div>
            <Link to="Contact" smooth={true} spy={true}>CONTACT</Link>
          </div>

          <div className="lang">
            <div>
              <img src={`/img/header/${props.lan}.svg`} alt="img" />
            </div>

            {props.lan === "kr" ? "KOR" : "ENG"}
          </div>

          <div className="hover-box">
            <ul>
              <li onClick={props.onChangeLanguage} data-lan="kr">
                <span>Korea, South</span>
              </li>
              <li onClick={props.onChangeLanguage} data-lan="en">
                <span>United States</span>
              </li>
            </ul>
          </div>
        </div>

        <div id="left"></div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ Home }) => {
  return {
    lan: Home.lan
  };
};

Header = connect(
  mapStateToProps,
  { onChangeLanguage }
)(Header);

export default Header;