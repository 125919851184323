// Main
export const MainLanguage = {
  kr: ["블록체인 기술을 넘어 IT산업을 주도하는 곳!", "도미넌트 랩은 분명 당신이 찾던 기업일 것입니다."],
  en: [
    "A place that leads the IT industry beyond blockchain technology!",
    "Dominant Lab is definitely the company you've been looking for.",
  ],
};

// Company
export const HomeLanguage = {
  kr: [
    "'공동체의 행복'",
    `도미넌트 랩은 전문성과 기술력을 바탕으로 팀원 간의 협동심을 도모하며
        변화하는 디지털 사회에서 공동체의 행복을 추구하는 목적 지향적 IT회사입니다.`,
    "'IT회사의 핵심'",
    `IT기술의 발달로 사회는 크게 변화하고 있으며,
        빠르게 변화하는 IT트렌드를 공유하고, 블록체인의 기술을 넘어 미래의 변화를 주도하는 곳!
        미래지향적인 플랫폼 개발을 위해 우리 도미넌트 랩은 끊임없이 도전하고 있습니다.`,
    "'신뢰의 가치'",
    `개인과 기업, 기업과 기업 사이의 파트너십, 그리고 관계의 중심 ‘신뢰’
        우리는 ‘파트너와 고객’ 모두가 함께 새로운 가치를 창출하고 함께 변화를 이끌어가고자 합니다.
        도미넌트 랩은 모두의 신뢰를 얻을 수 있는 기회를 환영합니다.`,
    "01. 블록체인 개발",
    `블록체인 기반의 (기업용) 솔루션 개발, 운영 및 파트너에게 필요한 인프라를 제공합니다.
        이를 위해 빠르게 변화하는 IT트렌드를 주도하며, 블록체인을 적용한 플랫폼의 개발 및 검토를 함께 진행합니다.
        도미넌트 랩은 당신의 안전하고 믿음직한 파트너가 되어드립니다.`,
    "02. 플랫폼 개발",
    "‘핵심 상호작용’",
    `플랫폼은 생산자와 소비자의 상호작용을 촉진해야 합니다. 파트너와 플랫폼 사용자의 ‘needs’를 파악하고
        이들이 쉽게 만나 가치교환을 할 수 있도록 성공적인 플랫폼 개발의 길이 되어드립니다.`,
    "03. 리서치",
    "‘모든 일의 시작’",
    `차별화 된 ‘자료검색과 수집’ 그리고 ‘데이터 분석 능력’, ‘확장된 사고’를 통해
        파트너의 선호 및 니즈를 파악하고 객관적인 자료 제공 및 전략을 마련합니다.`,
    "서울특별시 강남구 역삼로 17길 16, 2F　　+82-02-582-2381　　info@dominantlab.com",
    "이름",
    "연락처",
    "내용",
    "개인정보 보호정책에 동의합니다.",
  ],
  en: [
    "'Community happiness'",
    `Dominant Lab promotes cooperation among team members based on expertise and technology
        It is a purpose-oriented IT company that pursues the happiness of the community in a changing digital society.`,
    "'The core of an IT company'",
    `With the development of IT technology, society is changing significantly.
        A place that shares rapidly changing IT trends and leads future changes beyond blockchain technology!
        To develop a future-oriented platform, our Dominant Lab is constantly challenging.`,
    "'The value of trust'",
    `Partnership between individuals and companies, and between companies, and ‘trust’ at the center of the relationship
        We want both ‘partners and customers’ to create new value and lead change together.
        Dominant Lab welcomes opportunities to win everyone's trust.`,
    "01. Blockchain Development",
    `We provide the necessary infrastructure for developing, operating and partnering with blockchain-based (enterprise) solutions.
        To this end, we lead the rapidly changing IT trends and develop and review platforms that apply blockchain together.
        Dominant Lab will be your safe and reliable partner.`,
    "02. Platform development",
    "‘Core interactions’",
    `The platform should facilitate interaction between producers and consumers. Understand the ‘needs’ of partners and platform users
        We will be a path to successful platform development so that they can easily meet and exchange value.`,
    "03. Research",
    "‘The beginning of everything’",
    `Through differentiated ‘data search and collection’, ‘data analysis ability’ and ‘extended thinking’
        We identify partners' preferences and needs, and provide objective data and develop strategies.`,
    "16, Yeoksam-ro 17-gil, Gangnam-gu, Seoul, 2F　　+82-02-582-2381　　info@dominantlab.com",
    "Name",
    "Phone Number",
    "Content",
    "I agree to the Privacy Policy.",
  ],
};

// Slide
export const SlideLanguage = {
  kr: [
    "Lock, staking, Token swap 등 지갑 내 다양한 기능과 함께 OTP, reCAPTCHA, KYC 인증을 통해 안전성과 보안성을 강화하며, Web, Android, iOS를 함께 지원할 수 있도록 구현을 진행하였습니다.",
    "단순히 기업과 개인이 아닌 B2B, B2C, C2C, C2B까지 한 공간에 연결합니다. 기업만을 대상으로 구인구직을 할 수 있는 기존의 방식과는 다르게 플랫폼을 이용하는 개인 또한 구인이 가능하도록 구현작업을 진행하였습니다.",
    "토큰의 송금내역을 실시간으로 확인할 수 있도록 합니다. 사용자의 거래내역 뿐만 아니라 타인의 거래내역과 함께 공개된 데이터로 블록체인의 장부를 확인할 수 있도록 구현되었습니다.",
    "코인 정보부터 주문까지 한 눈에 파악할 수 있고 실시간 코인 동향, 정보를 확인할 수 있습니다.",
    "쇼핑몰에 등록된 코인으로 상품구매가 가능하며, 더하여 빗썸과의 연계로 BTC교환권 및 수수료 쿠폰 또한 구매할 수 있도록 구현 하였습니다.",
  ],
  en: [
    "In addition to various functions in the wallet such as lock, staking, and token swap, safety and security are strengthened through OTP, reCAPTCHA, and KYC authentication, and the implementation has been implemented to support Web, Android, and iOS together.",
    "It connects B2B, B2C, C2C, and C2B to one space, not simply businesses and individuals. Unlike the existing method that only allows companies to search for jobs, we implemented the implementation so that individuals using the platform can also find jobs.",
    "It allows you to check the remittance history of tokens in real time. It has been implemented so that users can check the ledger of the block chain with public data along with the transaction history of other users as well as the transaction history of the user.",
    "From coin information to orders, you can understand at a glance, and you can check real-time coin trends and information.",
    "Products can be purchased with coins registered in the shopping mall, and in addition, BTC exchange vouchers and fee coupons can be purchased in connection with Bithumb.",
  ],
};

//  Mobile side menu
export const SideLanguage = {
  kr: [
    "도미넌트 랩에게 궁금한 점이 있다면 ?",
    "파트너십과 신뢰를 기반으로, 탁월한 기술력을 활용하여 파트너의 서비스를 이해하고 파트너의 ‘생각’을 구현합니다. 블록체인 기술을 넘어 IT산업을 주도하는 곳, 도미넌트 랩은 분명 당신이 찾던 기업일 것입니다.",
  ],
  en: [
    "Do you have any questions about Dominant Lab?",
    "Based on partnership and trust, we utilize our superior technology to understand the services of our partners and implement their ‘thoughts’. A place that leads the IT industry beyond blockchain technology, Dominant Lab is definitely the company you were looking for.",
  ],
};
