import React, { useEffect } from "react";
import loadLib from "../../utils/loadLib";

// CSS
import "./Map.css";

const KakaoMap = (props) => {
  const onLoadedLib = () => {
    /* global kakao */
    kakao.maps.load(() => {
      const container = document.querySelector("#Map");
      const options = {
        center: new kakao.maps.LatLng(37.49600690991384, 127.0363603288798),
        level: 3,
      };
      const map = new kakao.maps.Map(container, options);

      let markerPosition = new kakao.maps.LatLng(37.49600690991384, 127.0363603288798);

      let marker = new kakao.maps.Marker({
        position: markerPosition,
      });

      marker.setMap(map);

      map.setCenter(markerPosition);
    });
  };

  useEffect(() => {
    if (!global.document.querySelector("#KakaoJSSDK")) {
      const url = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO}&libraries=services&autoload=false`;

      loadLib("Kakao", url, onLoadedLib);
    } else {
      onLoadedLib();
    }
  }, [props.mode]);

  return <div id="Map"></div>;
};

export default KakaoMap;
