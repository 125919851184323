const initialState = {
  lan: "kr",
};

const LANGUAGECHANGE = "HOME/LANGUAGECHANGE";

export const onChangeLanguage = e => dispatch => {
  dispatch({
    type: LANGUAGECHANGE,
    lan: e.currentTarget.dataset.lan
  });
};

export const Home = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGECHANGE:
      return {
        ...state,
        lan: action.lan
      }
    default:
      return state;
  }
};
