import React from "react";

// css
import "./Load.css";

let Load = () => {
  return (
    <div id="Load">
      <div className="load_wrap">
        <div className="logo_wrap">
          <img src="/img/header/nameLogo.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Load;
