import MHome from "../components/mobile/Home";

import { connect } from "react-redux";

const mapStateToProps = ({ Home }) => {
  return {
    lan: Home.lan
  };
};

const MHomeContainer = connect(
  mapStateToProps,
  {}
)(MHome);

export default MHomeContainer;
