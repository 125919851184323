import React from "react";
import { connect } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";

// CSS
import "./Main.css";

// modules
import { onChangeLanguage } from "../../../../store/modules/Home";

// language pack
import { MainLanguage } from "../../../../languagePack";

// Component
import Grid from "../Grid";
import Right from "../Side/Right";
import Left from "../Side/Left";

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Autoplay]);

let Main = (props) => {
  return (
    <div id="Main">
      <Swiper slidesPerView={1} autoplay={{ delay: 5000 }}>
        <SwiperSlide>
          <img src="/img/asset/back.jpg" alt="" />
        </SwiperSlide>

        <SwiperSlide>
          <img src="/img/asset/back2.jpg" alt="" />
        </SwiperSlide>

        <div className="main_content">
          <div className="main_title_wrap">
            <div className="title_top">
              <div>{MainLanguage[props.lan][0]}</div>

              <div>{MainLanguage[props.lan][1]}</div>

              <div className="box_line">
                <img src="/img/header/nameLogo.svg" alt="" />
              </div>
            </div>

            <div className="title_bottom">Blockchain Development to Keep Trust and Promise</div>
          </div>

          <Grid />
        </div>

        <Right />
        <Left />
      </Swiper>
    </div>
  );
};

const mapStateToProps = ({ Home }) => {
  return {
    lan: Home.lan,
  };
};

Main = connect(mapStateToProps, {
  onChangeLanguage,
})(Main);

export default Main;
