import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

// Component
import Grid from "../Common/Grid";
import Swiper from "../Common/Swiper";
import KakaoMap from "../../kakao";
import Right from "../Common/Side/Right";
import Left from "../Common/Side/Left";
import Carousel from "../Common/Carousel";

// modules
import { onChangeLanguage } from "../../../store/modules/Home";

// language pack
import { HomeLanguage } from "../../../languagePack";

// CSS
import "./Home.css";

let Home = (props) => {
  // const goTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 100);
  }, []);

  return (
    <div id="Home">
      <div className="home_contents">
        <div id="Partner">
          <div className="row L_title">
            <div>
              Partners
              <div className="b_square"></div>
            </div>
          </div>

          <div className="partner_contents">
            <Carousel mounted={mounted} />
          </div>
        </div>

        <div id="Company">
          <div className="row B_title">
            <div>
              COMPANY
              <div className="g_square"></div>
            </div>
          </div>

          <div className="row"></div>

          <div className="row company_name">DOMINENTLAB</div>

          <div className="company_contents">
            <div>
              <div className="title">{HomeLanguage[props.lan][0]}</div>

              <div className="title_info">{HomeLanguage[props.lan][1]}</div>
            </div>

            <div>
              <div className="title">{HomeLanguage[props.lan][2]}</div>

              <div className="title_info">{HomeLanguage[props.lan][3]}</div>
            </div>

            <div>
              <div className="title">{HomeLanguage[props.lan][4]}</div>

              <div className="title_info">{HomeLanguage[props.lan][5]}</div>
            </div>

            <div className="figure_1">
              <img src="/img/asset/figure_1.png" alt="" />
            </div>
          </div>
        </div>

        <div id="Member">
          <div className="member_title">
            <div>우리는 도미넌트 랩 입니다.</div>
            <div>WE ARE DOMINANT LAB.</div>
          </div>

          <div className="member_img">
            <img src="/img/asset/member.svg" alt="" />
          </div>

          <Right />
          <Left />
        </div>

        <div id="Business">
          <div className="row L_title">
            <div>
              Business
              <div className="b_square"></div>
            </div>
          </div>

          <div className="row"></div>
          <div className="row"></div>

          <div className="business_contents">
            <div>
              <div className="business_img">
                <img src="/img/asset/business_1.png" alt="" />
              </div>

              <div className="title">
                <div>{HomeLanguage[props.lan][6]}</div>
              </div>

              <div className="title_info">{HomeLanguage[props.lan][7]}</div>
            </div>

            <div>
              <div className="business_img">
                <img src="/img/asset/business_2.png" alt="" />
              </div>

              <div className="title">
                <div>{HomeLanguage[props.lan][8]}</div>
              </div>

              <div className="title_info">
                <div>{HomeLanguage[props.lan][9]}</div>

                {HomeLanguage[props.lan][10]}
              </div>
            </div>

            <div>
              <div className="business_img">
                <img src="/img/asset/business_3.png" alt="" />
              </div>

              <div className="title">
                <div>{HomeLanguage[props.lan][11]}</div>
              </div>

              <div className="title_info">
                <div>{HomeLanguage[props.lan][12]}</div>

                {HomeLanguage[props.lan][13]}
              </div>

              <div className="figure_3">
                <img src="/img/asset/figure_3.png" alt="" />
              </div>
            </div>

            <div className="figure_2">
              <img src="/img/asset/figure_2.png" alt="" />
            </div>
          </div>
        </div>

        <div id="Project">
          <div className="row B_title project_title">
            <div>
              PROJECTS
              <div className="g_square"></div>
            </div>
          </div>

          <div className="slide">
            <Swiper />
          </div>

          <Grid />

          <Right />
          <Left />
        </div>

        <div id="Media">
          <div className="figure_5">
            <img src="/img/asset/figure_5.png" alt="" />
          </div>

          <div className="row B_title">
            <div>
              MEDIA
              <div className="g_square"></div>
              <div className="btn_more">MORE</div>
            </div>
          </div>

          <div className="media_contents">
            <div className="figure_4">
              <img src="/img/asset/figure_4.png" alt="" />
            </div>

            <div>
              <div className="contents">
                <div>
                  <div className="title">How Does the Blockchain Work?</div>
                  <div className="text">
                    Blockchain technology is probably the best invention since the internet itself. It allows value exchange without the
                    need for trust or a central authority...
                  </div>

                  <a
                    href="https://onezero.medium.com/how-does-the-blockchain-work-98c8cd01d2ae"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="line_more"
                  >
                    MORE
                  </a>
                </div>

                <div className="img">
                  <img src="https://miro.medium.com/max/1400/1*YTwz_rEmaFjkq6hQBjnHvg.jpeg" alt="" />
                </div>
              </div>
            </div>

            <div>
              <div className="contents">
                <div>
                  <div className="title">
                    Games Will Be the Catalyst for <br />
                    Blockchain Mass Adoption
                  </div>
                  <div className="text">We’ve Been Hyper-focused on Blockchain Games at Loom Network — Here’s Why...</div>

                  <a
                    href="https://medium.com/loom-network/games-will-be-the-catalyst-for-blockchain-mass-adoption-628f818c6c87"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="line_more"
                  >
                    MORE
                  </a>
                </div>

                <div className="img">
                  <img src="https://miro.medium.com/max/4812/1*jIOJzUVf5Rf_FbhNEYbn2Q.png" alt="" />
                </div>
              </div>
            </div>

            <div>
              <div className="contents">
                <div>
                  <div className="title">Why Blockchain is Hard</div>
                  <div className="text">
                    What the heck is a blockchain, anyway? And can it really do all these things? Can blockchain bring something amazing to
                    industries as diverse as health care, finance, supply chain management and music rights?
                  </div>

                  <a
                    href="https://jimmysong.medium.com/why-blockchain-is-hard-60416ea4c5c"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="line_more"
                  >
                    MORE
                  </a>
                </div>

                <div className="img">
                  <img src="https://miro.medium.com/max/1400/0*W3LI86Xp8u_JEGfc.jpg" alt="" />
                </div>
              </div>
            </div>

            <div>
              <div className="contents">
                <div>
                  <div className="title">
                    Blockchain: the revolution we’re <br />
                    not ready for
                  </div>
                  <div className="text">
                    Imagine you didn’t need to trust a stranger to make a deal with them. Imagine you didn’t need to trust your bank to
                    deposit your money there. Imagine you didn’t need to trust your government to know it was being just and fair.
                  </div>

                  <a
                    href="https://medium.com/free-code-camp/blockchain-is-our-first-22nd-century-technology-d4ad45fca2ce"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="line_more"
                  >
                    MORE
                  </a>
                </div>

                <div className="img">
                  <img src="https://miro.medium.com/max/2048/0*JMQOodCGPz_Gxy5g.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="Location" className="L_title">
          <div className="row L_title">
            <div>
              Location
              <div className="b_square"></div>
            </div>
          </div>

          <div className="location_contents">
            <KakaoMap mode="web" />

            <div className="adress">
              <span>도미넌트 랩(DOMINANT LAB)</span>　　
              {HomeLanguage[props.lan][14]}
            </div>
          </div>
        </div>

        <div id="Contact">
          <div className="row B_title">
            <div>
              CONTACT
              <div className="g_square"></div>
            </div>
          </div>

          <div className="row"></div>
          <div className="row"></div>

          <div className="contact_conntents">
            <div className="contact_form">
              <div>{HomeLanguage[props.lan][15]}</div>
              <div className="input_area">
                <input type="text" placeholder="Name" />
              </div>

              <div>{HomeLanguage[props.lan][16]}</div>
              <div className="input_area">
                <input type="text" placeholder="Phone Number" />
              </div>

              <div>{HomeLanguage[props.lan][17]}</div>

              <div className="input_area">
                <textarea placeholder="Content" />
              </div>
            </div>

            <div className="agree">
              <div>{HomeLanguage[props.lan][18]}</div>
              <input type="checkbox" />
            </div>
            <div className="btn_send">SEND</div>
          </div>
        </div>
      </div>

      <Grid />

      {/* <div onClick={goTop} className={window.pageYOffset > 100 ? "top_go active" : "top_go"}>
        <img src="/img/footer/up.svg" alt="" />
      </div> */}
    </div>
  );
};

const mapStateToProps = ({ Home }) => {
  return {
    lan: Home.lan,
  };
};

Home = connect(mapStateToProps, { onChangeLanguage })(Home);

export default Home;
