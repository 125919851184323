import Home from "../components/web/Home";

import { connect } from "react-redux";

const mapStateToProps = ({ Home }) => {
  return {
    lan: Home.lan
  };
};

const HomeContainer = connect(
  mapStateToProps,
  {}
)(Home);

export default HomeContainer;
