import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { withRouter } from "react-router";

// Load Component
import Load from "./components/web/Common/Load";

// Web Component
import Header from "./components/web/Common/Header";
import Main from "./components/web/Common/Main/";
import Footer from "./components/web/Common/Footer";
import Right from "./components/web/Common/Side/Right";
import Left from "./components/web/Common/Side/Left";

// Mobile Component
import MHeader from "./components/mobile/Common/Header";
import MMain from "./components/mobile/Common/Main/";
import MFooter from "./components/mobile/Common/Footer";

// Constainers
import HomeContainer from "./containers/HomeContainer";
import MHomeContainer from "./containers/MHomeContainer";

// import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";

import { useMediaQuery } from "react-responsive";

// css
import "./App.css";

let App = () => {
  const [onLoad, setOnLoad] = useState(true);
  const isMobile = useMediaQuery({
    query: "(max-width:930px)",
  });

  useEffect(() => {
    const timeout = setTimeout(() => setOnLoad(false), 1000);

    return () => clearTimeout(timeout);
  });

  return (
    <div id="App">
      {onLoad ? (
        <Load />
      ) : !isMobile ? (
        <div className="on_web" style={{ animation: "fadeIn .5s" }}>
          <Header />
          <Right />
          <Left />

          <Main />
          <Route exact path="/" component={HomeContainer} />
          <Footer />
        </div>
      ) : (
        <div className="on_mobile" style={{ animation: "fadeIn .5s" }}>
          <MHeader />
          <MMain />
          <Route exact path="/" component={MHomeContainer} />
          <MFooter />
        </div>
      )}
    </div>
  );
};

export default withRouter(App);
