import React from "react";

// CSS
import "./MFooter.css";

let Footer = () => {
  return (
    <div id="MFooter">
      <div className="footer_contents">
        <div className="footer_info">
          <div>
            <div>서울특별시 역삼로 17길 16, 2F</div>
            <div>+82-02-582-2381　|　info@dominantlab.com</div>
          </div>

          <div className="copyright">Copyright © 2021 DOMINANT LAB. All Rights Reserved.</div>
        </div>

        <div className="logo">
          <img src="img/footer/logo.svg" alt="" />
        </div>

        <div className="back_logo">
          <img src="img/footer/s_logo.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
