import React from "react";

// CSS
import "./Carousel.css";

let Carousel = (props) => {
  return (
    <div id="Carousel">
      {/* <div className="main-ticker-wrap">
          <div className="main-ticker-body">
            <div className="item">
              <img src="/img/asset/kada-2.png" alt="" />
            </div>

            <div className="item">
              <img src="img/asset/hkba.png" alt="" />
            </div>

            <div className="item">
              <img src="img/asset/mmd.png" alt="" />
            </div>

            <div className="item">
              <img src="/img/asset/hashlike.png" alt="" />
            </div>

            <div className="item">
              <img src="/img/asset/startlive.png" alt="" />
            </div>

            <div className="item">
              <img src="/img/asset/kada-2.png" alt="" />
            </div>

            <div className="item">
              <img src="img/asset/hkba.png" alt="" />
            </div>

            <div className="item">
              <img src="img/asset/mmd.png" alt="" />
            </div>

            <div className="item">
              <img src="/img/asset/hashlike.png" alt="" />
            </div>

            <div className="item">
              <img src="/img/asset/startlive.png" alt="" />
            </div>
          </div>
        </div> */}
      <div className="flow_container">
        <div className="flow_wrap">
          <div className={props.mounted ? "flow" : ""}>
            <div className="flex items-end">
              <a className="logo" href="#1">
                <img src="/img/asset/kada-2.png" alt="" />
              </a>

              <a className="logo" href="#2">
                <img src="/img/asset/hkba.png" alt="" />
              </a>

              <a className="logo" href="#3">
                <img src="/img/asset/mmd.png" alt="" />
              </a>

              <a className="logo" href="#4">
                <img src="/img/asset/hashlike.png" alt="" />
              </a>

              <a className="logo" href="#5">
                <img src="/img/asset/startlive.png" alt="" />
              </a>
            </div>

            <div className="flex items-end">
              <a className="logo" href="#1">
                <img src="/img/asset/kada-2.png" alt="" />
              </a>

              <a className="logo" href="#2">
                <img src="/img/asset/hkba.png" alt="" />
              </a>

              <a className="logo" href="#3">
                <img src="/img/asset/mmd.png" alt="" />
              </a>

              <a className="logo" href="#4">
                <img src="/img/asset/hashlike.png" alt="" />
              </a>

              <a className="logo" href="#5">
                <img src="/img/asset/startlive.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
