import React, { useState } from "react";
import { connect } from "react-redux";
import {Link} from 'react-scroll'

// modules
import { onChangeLanguage } from "../../../../store/modules/Home";

// language pack
import { SideLanguage } from "../../../../languagePack";

// CSS
import "./MHeader.css";

let Header = (props) => {
  const [ toggle, setToggle ] = useState(false);

  return (
    <div id="MHeader">
      <div className="mobile_header_contents">
        <div>
          <Link to="App" smooth={true} spy={true}>
            <img src="/img/header/m_nameLogo.svg" alt=""/>
          </Link>
        </div>

        <div>
          <div
            data-menu="burger"
            className={toggle ? "burger_menu is-active" : "burger_menu"}
            onClick={() => setToggle(!toggle)}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </div>

          <div className={toggle ? "side-menu-active" : "side-menu"}>
            <div className="side-menu-wrap">
              <ul>
                <Link
                  activeClass="active"
                  to="Main"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={() => setToggle(false)}
                >
                  <li>HOME</li>
                </Link>

                <Link
                  activeClass="active"
                  to="MPartner"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={() => setToggle(false)}
                >
                  <li>COMPANY</li>
                </Link>

                <Link
                  activeClass="active"
                  to="MBusiness"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-50}
                  onClick={() => setToggle(false)}
                >
                  <li>BUSINESS</li>
                </Link>

                <Link
                  activeClass="active"
                  to="MProject"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-50}
                  onClick={() => setToggle(false)}
                >
                  <li>PROJECT</li>
                </Link>

                <Link
                  activeClass="active"
                  to="MMedia"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-50}
                  onClick={() => setToggle(false)}
                >
                  <li>MEDIA</li>
                </Link>

                <Link
                  activeClass="active"
                  to="MLocation"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-100}
                  onClick={() => setToggle(false)}
                >
                  <li>LOCATION</li>
                </Link>

                <Link
                  activeClass="active"
                  to="MContact"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-50}
                  onClick={() => setToggle(false)}
                >
                  <li>CONTACT</li>
                </Link>
              </ul>

              <div className="go_contact_wrap">
                <div>
                  {SideLanguage[props.lan][0]}
                </div>

                <div>
                  {SideLanguage[props.lan][1]}
                </div>

                <div>
                  <Link 
                  to="MContact"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-50}
                  onClick={() => setToggle(false)}
                  >Go contact → </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ Home }) => {
  return {
    lan: Home.lan
  };
};

Header = connect(
  mapStateToProps,
  { onChangeLanguage }
)(Header);

export default Header;