
import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";

// modules
import { onChangeLanguage } from "../../../../store/modules/Home";

// language pack
import { SlideLanguage } from "../../../../languagePack";

// CSS
import './Swiper.css';

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, Autoplay  } from "swiper";

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Autoplay ]);
// Swiper.use([Navigation, Pagination]);

let Slide = props =>{


    useEffect(() => {
        function Resize() {
            // const swiper = document.querySelector('.swiper-container').swiper;

            // swiper.width = window.innerWidth;
            // var swiper =  new Swiper('.swiper-container', {
            //     autoplay : {  
            //         delay : 3000
            //     },
            //     slidesPerView :1,
            //     navigation :true,
            //     observer: true,
            //     observeParents: true,

            // });

            // swiper.update();
        }
        
        window.addEventListener('resize', Resize);
  
        return () => {
          window.removeEventListener('resize', Resize)   
        }
    }, []);

    return (
        <div id="Swiper">
            <Swiper 
                slidesPerView={1}
                observer={true}
                autoplay={{ delay : 3000 }}
                navigation={true}
                >    
                <SwiperSlide>
                    <div className="row_1"></div>

                    <div className="row_1">
                        <div className="title">Blockchain Wallet</div>
                        
                        <div className="explanation">
                            {SlideLanguage[props.lan][0]}
                        </div> 
                    </div>

                    <div className="row_3">
                        <img src="/img/asset/wallet.png" alt=""/>
                    </div>

                    <div className="row_1 count">1</div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="row_1"></div>

                    <div className="row_1">
                        <div className="title jobus">
                            <img src="/img/asset/jobus_logo.svg" alt="" />
                        </div>

                        <div className="explanation">
                            {SlideLanguage[props.lan][1]}
                        </div> 
                    </div>

                    <div className="row_3">
                        <img src="/img/asset/jobus.png" alt=""/>
                    </div>

                    <div className="row_1 count">2</div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="row_1"></div>

                    <div className="row_1">
                        <div className="title explorer">Block Explorer</div>

                        <div className="explanation">
                            {SlideLanguage[props.lan][2]}
                        </div> 
                    </div>

                    <div className="row_3">
                        <img src="/img/asset/block.png" alt=""/>
                    </div>

                    <div className="row_1 count">3</div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="row_1"></div>

                    <div className="row_1">
                        <div className="title iconex">ICON<span>EX</span></div>

                        <div className="explanation">
                            {SlideLanguage[props.lan][3]}
                        </div> 
                    </div>

                    <div className="row_3">
                        <img src="/img/asset/iconex.png" alt=""/>
                    </div>

                    <div className="row_1 count">4</div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="row_1"></div>

                    <div className="row_1">
                        <div className="title flexmall">FLE<span>X</span>MALL</div>

                        <div className="explanation">
                            {SlideLanguage[props.lan][4]}
                        </div> 
                    </div>

                    <div className="row_3">
                        <img src="/img/asset/flexmall.png" alt=""/>
                    </div>

                    <div className="row_1 count">5</div>
                </SwiperSlide>
            </Swiper>
        </div>
      )
}

const mapStateToProps = ({ Home }) => {
    return {
      lan: Home.lan
    };
};
  
Slide = connect(
mapStateToProps,
{ onChangeLanguage }
)(Slide);

export default Slide;
