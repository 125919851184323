import React from "react";

// CSS
import "./Grid.css";

let Grid = () => {
  return (
    <div id="Grid">
      <div className="line row"></div>
      <div className="line row"></div>
      <div className="line row"></div>
      <div className="line row"></div>
      <div className="line row"></div>
      <div className="line row"></div>
    </div>
  );
};

export default Grid;